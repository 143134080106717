const config = {
  apiKey: "AIzaSyCwhCvO8NbTusBaHmHHnNT7yC0_11UL2RI",
  authDomain: "georgyo-avalon.firebaseapp.com",
  databaseURL: "https://georgyo-avalon-default-rtdb.firebaseio.com",
  projectId: "georgyo-avalon",
  storageBucket: "georgyo-avalon.appspot.com",
  messagingSenderId: "1000859874531",
  appId: "1:1000859874531:web:789f785c58c574bff181d6"
};

export default config;
